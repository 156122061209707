// Sdt Green tones
import {
  ABMER_DARKEN_2,
  BLUE_DARKEN_1,
  BLUE_DARKEN_4,
  BLUE_GREY_DARKEN_1,
  BROWN_DARKEN_1,
  DEEP_ORANGE_DARKEN_4,
  GREEN_DARKEN_2,
  GREEN_DARKEN_4,
  GREY_DARKEN_4,
  TEAL_DARKEN_1
} from '@/components/AppColors'

interface Rank {
  key: string;
  order: number;
  color: string;
}

export const REKR: Rank = { key: 'Rekr', order: 1, color: TEAL_DARKEN_1 }
export const SDT: Rank = { key: 'Sdt', order: 2, color: GREEN_DARKEN_2 }
export const GFR: Rank = { key: 'Gfr', order: 3, color: GREEN_DARKEN_4 }
// Uof Orange tones
export const KPL: Rank = { key: 'Kpl', order: 4, color: ABMER_DARKEN_2 }
export const FW: Rank = { key: 'Fw', order: 5, color: DEEP_ORANGE_DARKEN_4 }
export const FOUR: Rank = { key: 'Four', order: 6, color: BROWN_DARKEN_1 }
// Of Blue tones
export const LT: Rank = { key: 'Lt', order: 7, color: BLUE_DARKEN_1 }
export const OBLT: Rank = { key: 'Oblt', order: 8, color: BLUE_DARKEN_4 }
export const HPTM: Rank = { key: 'Hptm', order: 9, color: GREY_DARKEN_4 }

export const RANKS = [REKR, SDT, GFR, KPL, FW, FOUR, LT, OBLT, HPTM]

export function getRankColor(rankKey: string): string {
  const rank = RANKS.find(r => r.key === rankKey)
  return rank ? rank.color : BLUE_GREY_DARKEN_1
}

export function getRankOrder(rankKey: string): number {
  const rank = RANKS.find(r => r.key === rankKey)
  return rank ? rank.order : 0
}
