
import { Component, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import {
  byNumberOfPersons,
  filterOrgActive,
  groupPersonsByUnit,
  PersonGroup
} from '@/components/util/PersonsLodashUtil'
import CsvUpload from '@/components/csv-upload/CsvUpload.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import PersonSearchList from '@/components/persons/search-overlay/PersonSearchList.vue'
import PersonsByFunctionDonutChart from '@/components/persons/charts/PersonsByFunctionDonutChart.vue'
import PersonsByFormationDonutChart from '@/components/persons/charts/PersonsByFormationDonutChart.vue'
import PrognosisByFunctionBarChart from '@/components/persons/charts/PrognosisByFunctionBarChart.vue'
import EntlassungenByFunctionBarChart from '@/components/persons/charts/EntlassungenByFunctionBarChart.vue'
import PersonsByFunctionBarChart from '@/components/persons/charts/PersonsByFunctionBarChart.vue'
import { trace } from '@/services/log-client'
import PersonsCounterCard from '@/components/persons/counters/PersonsCounterCard.vue'

@Component({
  components: {
    PersonsCounterCard,
    PersonsByFunctionBarChart,
    EntlassungenByFunctionBarChart,
    PrognosisByFunctionBarChart,
    PersonsByFormationDonutChart,
    PersonsByFunctionDonutChart,
    ScrollToTop,
    CsvUpload,
    PersonSearchList
  }
})
export default class UnitCharts extends Vue {
  selectedUnitKey = null

  get orgActivePersons(): Person[] {
    return filterOrgActive(this.$store.getters['personfile/persons'], this.$store.getters['tenant/tenant'])
  }

  get personsByUnit(): PersonGroup[] {
    return groupPersonsByUnit(this.orgActivePersons)
      .sort(byNumberOfPersons)
  }

  get selectedUnit(): PersonGroup {
    return this.personsByUnit.find(g => g.key === this.selectedUnitKey)
  }

  selectUnit(key: string) {
    this.selectedUnitKey = key
    trace({ key: 'select_unit', route: this.$route.path, unit: key })
    /*
    this.$vuetify.goTo(this.target, {
      duration: 300,
      offset: 0,
      easing: 'linear'
    })
    */
  }

  /*
  get target(): any {
    return this.$refs.selectChips
  }
   */
}
