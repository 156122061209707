
import { Component, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import PersonSearchCards from '@/components/persons/search/PersonSearchCards.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import PersonSearchList from '@/components/persons/search-overlay/PersonSearchList.vue'

@Component({
  components: { PersonSearchList, ScrollToTop, PersonSearchCards }
})
export default class PersonSearch extends Vue {
  get persons(): Person[] {
    return this.$store.getters['personfile/persons']
  }

  search = ''

  showOtherOrgs = false

  get filteredPersons(): Person[] {
    return this.persons
      .filter(person => this.filterOtherOrgs(person, this.showOtherOrgs))
      .filter(person => this.filterSearch(person, this.search))
  }

  private filterOtherOrgs(person: Person, showNonBsw: boolean): boolean {
    return showNonBsw ? true : (person.orgKey === this.$store.getters['tenant/orgKey'])
  }

  private filterSearch(person: Person, search: string): boolean {
    if (!search || search.trim().length < 2) {
      return true
    }

    const searchWords = search.split(' ').filter(str => !!str)

    return searchWords.every(searchWord => this.anyMatch(Object.values(person), searchWord))
  }

  private anyMatch(properties: any[], searchWord: string): boolean {
    return properties
      .filter(prop => !!prop)
      .some(prop => String(prop).toLowerCase().includes(searchWord.toLowerCase()))
  }
}
