import { getTenant, isTenantValid, patchInactiveFormations, patchShowHints } from '@/services/tenant-client'

export const tenant = {
  namespaced: true,
  state: {
    tenant: null,
    valid: null
  },
  actions: {
    async fetchValid({ commit }) {
      try {
        const response = await isTenantValid()
        commit('setValid', response.data)
      } catch (error) {
        console.log(error)
      }
    },
    async fetchTenant({ commit }) {
      try {
        const response = await getTenant()
        console.log('fetched tenant')
        commit('setTenant', response.data)
      } catch (error) {
        console.log(error)
      }
    },
    async patchShowHints({ commit }, showHints) {
      try {
        commit('setShowHints', showHints)
        await patchShowHints(showHints)
      } catch (error) {
        console.log(error)
      }
    },
    async patchInactiveFormations({ commit }, formationKeys) {
      try {
        commit('setInactiveFormations', formationKeys)
        await patchInactiveFormations(formationKeys)
      } catch (error) {
        console.log(error)
      }
    }
  },
  mutations: {
    setValid(state, data) {
      state.valid = data
    },
    setTenant(state, data) {
      state.tenant = data
    },
    setShowHints(state, data: boolean) {
      state.tenant.showHints = data
    },
    setInactiveFormations(state, data: string[]) {
      state.tenant.inactiveFormations = data
    },
    prune(state) {
      state.valid = null
      state.tenant = null
    }
  },
  getters: {
    tenant: state => {
      return state.tenant
    },
    valid: state => {
      return state.valid
    },
    orgKey: state => {
      return state.tenant?.orgKey
    },
    showHints: state => {
      return state.tenant?.showHints
    },
    inactiveFormations: state => {
      return state.tenant?.inactiveFormations
    },
    livePath: state => {
      return state.tenant?.livePath
    }
  }
}
