import { Org } from '@/components/model'

export const SONSTIGE = 'Sonstige'

export function getOrgName(orgKey: string, orgs: Org[]) {
  const org = orgs.find(org => org.orgKey === orgKey)
  if (!org) {
    return SONSTIGE
  }
  return org.name
}
