
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import { groupPersonsLocalOrg, PersonGroup2 } from '@/components/util/PersonsLodashUtil'
import { getOrgName } from '@/data/orgs'
import { BLUE_DARKEN_1, BLUE_DARKEN_4 } from '@/components/AppColors'
import PersonsDonutChart from '@/components/persons/charts/common/PersonsDonutChart.vue'

@Component({
  components: { PersonsDonutChart }
})
export default class PersonsByRegionDonutChart extends Vue {
  @Prop()
  persons!: Person[]

  get personGroups(): PersonGroup2[] {
    return groupPersonsLocalOrg(this.persons, this.$store.getters['tenant/tenant'])
      .map(g => ({
        key: g.key,
        persons: g.persons,
        label: getOrgName(g.key, this.$store.getters['org/orgs']),
        color: this.getColor(g.key)
      }))
  }

  private getColor(orgKey: string): string {
    if (orgKey === this.$store.getters['tenant/orgKey']) {
      return BLUE_DARKEN_4
    }

    return BLUE_DARKEN_1
  }
}
