import { CSV_COURSE_MAPPING } from '@/components/model'

export const CSVCOURSE_MAPPING: CSV_COURSE_MAPPING = {
  status: {
    name: 'status',
    required: false,
    mappings: [
      {
        csvFieldname: 'Status / Kdpfl'
      }
    ]
  },
  courseNumber: {
    name: 'courseNumber',
    required: false,
    mappings: [
      {
        csvFieldname: 'Anlassnummer'
      }
    ]
  },
  name: {
    name: 'name',
    required: true,
    mappings: [
      {
        csvFieldname: 'Bezeichnung'
      }
    ]
  },
  type: {
    name: 'type',
    required: true,
    mappings: [
      {
        csvFieldname: 'Anlassart'
      }
    ]
  },
  bzgArt: {
    name: 'bzgArt',
    required: true,
    mappings: [
      {
        csvFieldname: 'BZG-Artikel'
      }
    ]
  },
  participationType: {
    name: 'participationType',
    required: true,
    mappings: [
      {
        csvFieldname: 'Teilnehmerart'
      }
    ]
  },
  functionAbbr: {
    name: 'functionAbbr',
    required: true,
    mappings: [
      {
        csvFieldname: 'Funktion'
      }
    ]
  },
  eoCode: {
    name: 'eoCode',
    required: false,
    mappings: [
      {
        csvFieldname: 'EO-Code'
      }
    ]
  },
  location: {
    name: 'location',
    required: true,
    mappings: [
      {
        csvFieldname: 'Einrückort'
      }
    ]
  },
  refNr: {
    name: 'refNr',
    required: true,
    mappings: [
      {
        csvFieldname: 'RefNr'
      }
    ]
  },
  startDate: {
    name: 'startDate',
    required: true,
    mappings: [
      {
        csvFieldname: 'Eff. EinrDatum'
      }
    ]
  },
  startTime: {
    name: 'startTime',
    required: true,
    mappings: [
      {
        csvFieldname: 'Eff. EinrZeit'
      }
    ]
  },
  endDate: {
    name: 'endDate',
    required: true,
    mappings: [
      {
        csvFieldname: 'Eff. EntlDatum'
      }
    ]
  },
  endTime: {
    name: 'endTime',
    required: false,
    mappings: [
      {
        csvFieldname: 'Eff. EntlZeit'
      }
    ]
  },
  durationDays: {
    name: 'durationDays',
    required: false,
    mappings: [
      {
        csvFieldname: 'Dauer (Tage)'
      }
    ]
  },
  achievedDays: {
    name: 'achievedDays',
    required: false,
    mappings: [
      {
        csvFieldname: 'Dienst- tage'
      }
    ]
  }
}
