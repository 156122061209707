export const coursefile = {
  namespaced: true,
  state: {
    file: null,
    courses: [],
    showError: false,
    processing: false
  },
  mutations: {
    setFile(state, file) {
      state.file = file
    },
    setCourses(state, courses) {
      state.courses = courses
    },
    setShowError(state, showError) {
      state.showError = showError
    },
    setProcessing(state, processing) {
      state.processing = processing
    },
    prune(state) {
      state.file = null
      state.courses = []
      state.showError = false
      state.processing = false
    }
  },
  getters: {
    file: state => state.file,
    courses: state => state.courses,
    showError: state => state.showError,
    processing: state => state.processing
  }
}
