
import { Component, Vue } from 'vue-property-decorator'
import { postPasswordChange } from '@/services/password-client'
import { trace } from '@/services/log-client'

@Component
export default class PasswordChange extends Vue {
  private oldPassword = ''
  private newPassword = ''
  private newPassword2 = ''

  private showOldPassword = false
  private showNewPassword = false
  private showNewPassword2 = false

  private alertMessage = ''

  savePassword(): void {
    if (!this.validateForm()) {
      return
    }

    postPasswordChange(this.oldPassword, this.newPassword)
      .then(() => {
        this.alertMessage = 'Passwort wurde geändert'
        this.clearForm()
        trace({ msg: 'Passwort wurde geändert' })
      })
      .catch(err => {
        if (err.response.data.message === 'INVALID_OLD_PW') {
          this.alertMessage = 'Das alte Passwort ist nicht korrekt.'
          trace({ msg: 'Das alte Passwort ist nicht korrekt.' })
        } else {
          this.alertMessage = 'Passwort konnte nicht geändert werden'
          trace({ msg: 'Passwort konnte nicht geändert werden' })
        }
      })
  }

  private validateForm(): boolean {
    if (this.newPassword !== this.newPassword2) {
      this.alertMessage = 'Stellen Sie sicher, dass das die Passwörter übereinstimmen.'
      trace({ msg: 'Stellen Sie sicher, dass das die Passwörter übereinstimmen.' })
      return false
    }

    return true
  }

  private clearForm() {
    this.oldPassword = ''
    this.newPassword = ''
    this.newPassword2 = ''
  }
}
