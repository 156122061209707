import { render, staticRenderFns } from "./PersonSearchCards.vue?vue&type=template&id=f7f1b70a&"
import script from "./PersonSearchCards.vue?vue&type=script&lang=ts&"
export * from "./PersonSearchCards.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports