import { APP_COLORS, RANDOM_COLORS } from '@/components/AppColors'

export class InsightsColorPicker {
  private index = 0

  next() {
    if (this.index === APP_COLORS.length) {
      this.index = 0
    }
    return APP_COLORS[this.index++]
  }
}

export const getRandomColor = () => RANDOM_COLORS[Math.floor(Math.random() * RANDOM_COLORS.length)]
