
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { PersonsBarchartBar, Person } from '@/components/model'
import {
  getFunctionsDistinct,
  groupPersonsByEntlassung,
  groupPersonsByFunction
} from '@/components/util/PersonsLodashUtil'
import BarChartDecorator from '@/components/chart-decorators/BarChartDecorator'
import { increment } from '@/components/util/ArrayUtil'
import PersonGroupOverlay from '@/components/persons/search-overlay/PersonGroupOverlay.vue'
import { InsightsColorPicker } from '@/components/util/InsightsColorPicker'
import { trace } from '@/services/log-client'

@Component({
    components: { PersonGroupOverlay, BarChartDecorator }
  }
)
export default class EntlassungenByFunctionBarChart extends Vue {
  @Prop()
  persons!: Person[]

  @Prop()
  subtitle: string

  LENGTH = 12

  private dueYears = increment(new Date().getFullYear(), this.LENGTH)

  selectedGroups: PersonsBarchartBar = null

  showOverlay = false

  private showPersons(index: number) {
    this.selectedGroups = this.personsByDueAndFunction[index]
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'EntlassungenByFunctionBarChart',
      group: this.selectedGroups.label,
      sumPersons: this.selectedGroups.sumPersons
    })
  }

  get personsByDueAndFunction(): PersonsBarchartBar[] {
    return groupPersonsByEntlassung(this.persons, this.dueYears)
      .map(group => ({
        label: group.key,
        persons: groupPersonsByFunction(group.persons),
        sumPersons: group.persons.length
      }))
  }

  get chartData(): Chart.ChartData {
    const colorPicker = new InsightsColorPicker()
    return {
      labels: this.personsByDueAndFunction.map(p => [p.label, '(' + p.sumPersons + ')']),
      datasets: getFunctionsDistinct(this.persons).map(f => ({
        label: f,
        backgroundColor: colorPicker.next(),
        data: this.personsByDueAndFunction.map(p => this.createData(p, f))
      }))
    }
  }

  private createData(bar: PersonsBarchartBar, functionKey: string) {
    const byFunction = bar.persons.find(grp => grp.key === functionKey)
    return byFunction ? byFunction.persons.length : undefined
  }
}
