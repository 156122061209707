
import { Component, Vue } from 'vue-property-decorator'
import { Tenant } from '@/components/model'
import InactiveFormations from '@/components/profile-settings/InactiveFormations.vue'
import { getOrgName } from '@/data/orgs'
import Spinner from '@/components/Spinner.vue'
import PasswordChange from '@/views/profile/PasswordChange.vue'
import ProfileEdit from '@/views/profile/ProfileEdit.vue'
import License from '@/views/profile/License.vue'

@Component({
  components: { License, ProfileEdit, PasswordChange, Spinner, InactiveFormations }
})
export default class ProfileView extends Vue {
  get tenant(): Tenant {
    return this.$store.getters['tenant/tenant']
  }

  get tenantValid(): boolean {
    return this.$store.getters['tenant/valid']
  }

  get showHints() {
    return this.$store.getters['tenant/showHints'] === true
  }

  set showHints(value) {
    this.$store.dispatch('tenant/patchShowHints', value)
  }

  get username(): string {
    return this.$store.getters['auth/username']
  }

  get orgName(): string {
    return getOrgName(this.tenant.orgKey, this.$store.getters['org/orgs'])
  }

  doLogout() {
    this.$store.dispatch('auth/logout').then(() => {
      this.$store.commit('personfile/prune')
      this.$store.commit('tenant/prune')
      this.$store.commit('userprofile/prune')
      this.$router.push('/login')
    })
  }
}
