import { City, CsvPerson, Person } from '@/components/model'
import { guessOrg } from '@/data/cities'

function createFullname(csvPerson: CsvPerson): string {
  if (!csvPerson.firstname && !csvPerson.name) {
    return 'AdZS'
  }

  return (csvPerson.name ?? '') + ' ' + (csvPerson.firstname ?? '')
}

export function createPerson(csvPerson: CsvPerson, cities: City[]): Person {
  return Object.assign({}, {
    plz: csvPerson.plz,
    city: csvPerson.city,
    yearOfBirth: csvPerson.yearOfBirth,
    orgKey: csvPerson.orgKey,
    sdPflichtUntil: csvPerson.sdPflichtUntil,
    rank: csvPerson.rank,
    functionKey: csvPerson.functionKey,
    formationKey: csvPerson.formationKey,
    formationLabel: csvPerson.formationLabel,
    unit: csvPerson.unit,
    phone: csvPerson.phone,
    fullname: createFullname(csvPerson),
    localOrgKey: guessOrg(csvPerson.plz, csvPerson.city, cities)
  })
}
