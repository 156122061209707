import { parse, ParseResult } from 'papaparse'
import { City, CSV_PERSON_MAPPING_SCHEMA, CsvPerson, Person } from '@/components/model'
import { createSchema } from '@/components/csv-upload/CsvPersonSchemaCreator'
import { createCsvPerson } from '@/components/csv-upload/CsvPersonCreator'
import { createPerson } from '@/components/csv-upload/PersonCreator'
import { info } from '@/services/log-client'

function validate(csvPerson: CsvPerson) {
  return !!csvPerson.plz
}

function createPersons(csvLines: any[], cities: City[]): Promise<Person[]> {
  // MAPPING ---[required, select fieldmapping]---> SCHEMA ---> CsvPerson ---[filter, add data]---> Person

  let schema: CSV_PERSON_MAPPING_SCHEMA

  try {
    schema = createSchema(csvLines)

    info({
      key: 'schema_created',
      schema: JSON.stringify(schema)
    })
  } catch (err) {
    return Promise.reject(err)
  }

  const persons: Person[] = csvLines
    .map(line => createCsvPerson(line, schema))
    .filter(csvPerson => validate(csvPerson))
    .map(csvPerson => createPerson(csvPerson, cities))

  return new Promise<Person[]>(function (resolve, reject) {
    if (persons.length > 0) {
      resolve(persons)
    } else {
      reject(new Error())
    }
  })
}

export function parseFile(csv: File, encoding: string, cities: City[]): Promise<Person[]> {
  if (!csv) {
    return Promise.resolve([])
  }

  return new Promise<Person[]>((resolve, reject) => {
    parse<File>(
      csv,
      {
        header: true,
        worker: true,
        skipEmptyLines: 'greedy',
        encoding: encoding, // https://stackoverflow.com/questions/40790042/filereader-which-encodings-are-supported
        complete(results: ParseResult<object>): void {
          createPersons(results.data, cities)
            .then(persons => resolve(persons))
            .catch(noPersonsError => reject(noPersonsError)) // persons.length == 0, or malformed csv
        }
      })
  })
}
