
import { Component, Vue } from 'vue-property-decorator'
import TermsContent from '@/components/info/TermsContent.vue'
import { UserProfile } from '@/components/model'

@Component({
  components: { TermsContent }
})
export default class AcceptTerms extends Vue {
  selected = false

  get acceptedTerms(): UserProfile {
    return this.$store.getters['userprofile/acceptedTerms']
  }

  acceptTerms() {
    this.$store.dispatch('userprofile/acceptTerms', this.selected)
  }
}
