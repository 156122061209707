
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { Person } from '@/components/model'
import { groupPersonsByCity, PersonGroup, sortBySizeAndLimit } from '@/components/util/PersonsLodashUtil'
import BarChartDecorator from '@/components/chart-decorators/BarChartDecorator'
import { BLUE_DARKEN_4 } from '@/components/AppColors'
import PersonListOverlay from '@/components/persons/search-overlay/PersonListOverlay.vue'
import { trace } from '@/services/log-client'

@Component({
    components: { PersonListOverlay, BarChartDecorator }
  }
)
export default class PersonsByCityBarChart extends Vue {
  @Prop()
  persons!: Person[]

  selectedGroup: PersonGroup = null

  showDialog = false

  private showPersons(index: number) {
    this.selectedGroup = this.personsByCityLimit24[index]
    this.showDialog = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'PersonsByCityBarChart',
      group: this.selectedGroup.key,
      sumPersons: this.selectedGroup.persons.length
    })
  }

  get personsByCityLimit24() {
    const LIMIT = 24
    return sortBySizeAndLimit(groupPersonsByCity(this.persons), LIMIT)
  }

  get chartData(): Chart.ChartData {
    const items = this.personsByCityLimit24
    return this.createChartData(items)
  }

  private createChartData(items: PersonGroup[]): Chart.ChartData {
    return {
      labels: items.map(p => p.key),
      datasets: [
        {
          label: 'Total',
          backgroundColor: BLUE_DARKEN_4,
          data: items.map(d => d.persons.length)
        }
      ]
    }
  }
}
