import { getLicenseRenewRequested } from '@/services/license-client'

export const license = {
  namespaced: true,
  state: {
    renewRequested: null
  },
  actions: {
    async fetchRenewRequested({ commit }) {
      try {
        const response = await getLicenseRenewRequested()
        console.log('fetched LicenseRenewRequested')
        commit('setLicenseRenewRequested', response.data)
      } catch (error) {
        console.log(error)
      }
    }
  },
  mutations: {
    setLicenseRenewRequested(state, data) {
      state.renewRequested = data
    },
    prune(state) {
      state.renewRequested = null
    }
  },
  getters: {
    renewRequested: state => {
      return state.renewRequested
    }
  }
}
