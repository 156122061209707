import Vue from 'vue'
import Vuex from 'vuex'
import { auth } from '@/store/auth.module'
import { tenant } from '@/store/tenant.module'
import { personfile } from '@/store/personfile.module'
import { city } from '@/store/city.module'
import { userprofile } from '@/store/userprofile.module'
import { org } from '@/store/org.module'
import { system } from '@/store/system.module'
import { license } from '@/store/license.module'
import { coursefile } from '@/store/coursefile.module'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    auth,
    system,
    tenant,
    userprofile,
    city,
    org,
    personfile,
    coursefile,
    license
  },
  state: {
    email: 'admin@zs-info.ch'
  },
  getters: {
    email: state => state.email
  }
})
