import { getCities } from '@/services/city-client'

export const city = {
  namespaced: true,
  state: {
    cities: null
  },
  actions: {
    async fetchCities({ commit }) {
      try {
        const response = await getCities()
        console.log('fetched cities')
        commit('setCities', response.data)
        return response.data
      } catch (error) {
        console.log(error)
      }
    }
  },
  mutations: {
    setCities(state, data) {
      state.cities = data
    }
  },
  getters: {
    cities: state => state.cities
  }
}
