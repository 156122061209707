import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import { formatDate } from '@/components/util/datefns-util'

Vue.filter('dateToString', function (date: Date) {
  if (!date) {
    return ''
  }
  return formatDate(date)
})

new Vue({
  router,
  store, // inject the store into all child components
  vuetify,
  render: h => h(App)
}).$mount('#app')
