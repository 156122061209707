
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { Person, PersonsBarchartBar, Unit } from '@/components/model'
import { groupPersonsByUnit, groupPersonsByYearOfBirth } from '@/components/util/PersonsLodashUtil'
import BarChartDecorator from '@/components/chart-decorators/BarChartDecorator'
import PersonGroupOverlay from '@/components/persons/search-overlay/PersonGroupOverlay.vue'
import { UNITS } from '@/data/units'
import { trace } from '@/services/log-client'

@Component({
    components: { PersonGroupOverlay, BarChartDecorator }
  }
)
export default class PersonsByUnitBarChart extends Vue {
  @Prop()
  persons!: Person[]

  selectedGroups: PersonsBarchartBar = null

  showOverlay = false

  private showPersons(index: number) {
    this.selectedGroups = this.personsByYearAndUnit[index]
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'PersonsByUnitBarChart',
      group: this.selectedGroups.label,
      sumPersons: this.selectedGroups.sumPersons
    })
  }

  get personsByYearAndUnit(): PersonsBarchartBar[] {
    return groupPersonsByYearOfBirth(this.persons)
      .map(group => ({
        label: group.key, // year of birth
        persons: groupPersonsByUnit(group.persons), // persons by unit
        sumPersons: group.persons.length
      }))
  }

  get chartData(): Chart.ChartData {
    const bars = this.personsByYearAndUnit
    return {
      labels: bars.map(p => [p.label, '(' + p.sumPersons + ')']),
      datasets: UNITS.map(u => ({
        label: u.name.join('/'),
        backgroundColor: u.color,
        data: bars.map(c => this.createData(c, u))
      }))
    }
  }

  private createData(bar: PersonsBarchartBar, unit: Unit) {
    const byUnit = bar.persons.find(grp => unit.name.includes(grp.key))
    return byUnit ? byUnit.persons.length : undefined
  }
}

