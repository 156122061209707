export const BLUE_DARKEN_4 = '#0D47A1'
export const BLUE_DARKEN_1 = '#1E88E5'
export const TEAL_DARKEN_1 = '#00897B'
export const GREEN_DARKEN_2 = '#388E3C'
export const GREEN_DARKEN_4 = '#1B5E20'
export const ABMER_DARKEN_2 = '#FFA000'
export const DEEP_ORANGE_DARKEN_4 = '#BF360C'
export const BROWN_DARKEN_1 = '#6D4C41'
export const GREY_DARKEN_4 = '#212121'
export const BLUE_GREY_DARKEN_1 = '#546E7A'

export const APP_COLORS = [
  BLUE_DARKEN_4,
  BLUE_DARKEN_1,
  TEAL_DARKEN_1,
  GREEN_DARKEN_2,
  GREEN_DARKEN_4,
  ABMER_DARKEN_2,
  DEEP_ORANGE_DARKEN_4,
  BROWN_DARKEN_1,
  GREY_DARKEN_4,
  BLUE_GREY_DARKEN_1
]

export const RANDOM_COLORS = [
  '#8E24AA',
  '#4A148C',
  '#5E35B1',
  '#311B92',
  '#3949AB',
  '#1A237E',
  '#1E88E5',
  '#0D47A1',
  '#039BE5',
  '#0277BD',
  '#00ACC1',
  '#006064',
  '#00897B',
  '#004D40',
  '#00695C',
  '#43A047',
  '#2E7D32',
  '#1B5E20',
  '#689F38',
  '#33691E',
  '#9E9D24',
  '#827717',
  '#FBC02D',
  '#F57F17',
  '#FFB300',
  '#FF6F00',
  '#E65100',
  '#BF360C',
  '#6D4C41',
  '#3E2723',
  '#546E7A',
  '#37474F',
  '#757575',
  '#424242'
]
