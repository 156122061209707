import { CSV_PERSON_MAPPING } from '@/components/model'

export const CSVPERSON_MAPPING: CSV_PERSON_MAPPING = {
  name: {
    name: 'name',
    required: false,
    mappings: [
      {
        csvFieldname: 'Name'
      }
    ]
  },
  firstname: {
    name: 'firstname',
    required: false,
    mappings: [
      {
        csvFieldname: 'Vorname'
      }
    ]
  },
  plz: {
    name: 'plz',
    required: true,
    mappings: [
      {
        csvFieldname: 'Postleitzahl'
      }
    ]
  },
  city: {
    name: 'city',
    required: true,
    mappings: [
      {
        csvFieldname: 'Ort'
      }
    ]
  },
  yearOfBirth: {
    name: 'yearOfBirth',
    required: true,
    mappings: [
      {
        csvFieldname: 'Jahrgang'
      }
    ]
  },
  orgKey: {
    name: 'orgKey',
    required: true,
    mappings: [
      {
        csvFieldname: 'Org.Kurzbez.'
      }
    ]
  },
  sdPflichtUntil: {
    name: 'sdPflichtUntil',
    required: true,
    mappings: [
      {
        csvFieldname: 'SD-Pflicht bis',
        format: 'dd.MM.yyyy'
      }
    ]
  },
  rank: {
    name: 'rank',
    required: true,
    mappings: [
      {
        csvFieldname: 'Grad'
      }
    ]
  },
  functionKey: {
    name: 'functionKey',
    required: true,
    mappings: [
      {
        csvFieldname: 'Funktion'
      }
    ]
  },
  formationKey: {
    name: 'formationKey',
    required: true,
    mappings: [
      {
        csvFieldname: 'Form.Kurzbez.'
      }
    ]
  },
  formationLabel: {
    name: 'formationLabel',
    required: true,
    mappings: [
      {
        csvFieldname: 'Einteilung'
      }
    ]
  },
  unit: {
    name: 'unit',
    required: true,
    mappings: [
      {
        csvFieldname: 'Fachbereich'
      },
      {
        csvFieldname: 'Fachbereich Funktion'
      }
    ]
  },
  phone: {
    name: 'phone',
    required: false,
    mappings: [
      {
        csvFieldname: 'Telefonnummer'
      }
    ]
  }
}
