
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import { groupPersonsByFunction, PersonGroup2 } from '@/components/util/PersonsLodashUtil'
import { InsightsColorPicker } from '@/components/util/InsightsColorPicker'
import PersonsDonutChart from '@/components/persons/charts/common/PersonsDonutChart.vue'

@Component({
  components: {
    PersonsDonutChart
  }
})
export default class PersonsByFunctionDonutChart extends Vue {
  @Prop()
  persons!: Person[]

  @Prop()
  subtitle: string

  get personGroups(): PersonGroup2[] {
    const colorPicker = new InsightsColorPicker()
    return groupPersonsByFunction(this.persons)
      .map(g => ({
        key: g.key,
        persons: g.persons,
        label: g.key,
        color: colorPicker.next()
      }))
  }
}
