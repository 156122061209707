
import { Component, Vue } from 'vue-property-decorator'
import ScrollToTop from '@/components/ScrollToTop.vue'
import { Course } from '@/components/model'
import CourseDaysByTypeBarChart from '@/components/courses/charts/CourseDaysByTypeBarChart.vue'

@Component({
  components: {
    CourseDaysByTypeBarChart,
    ScrollToTop
  }
})
export default class CoursesCharts extends Vue {
  get courses(): Course[] {
    return this.$store.getters['coursefile/courses']
  }
}
