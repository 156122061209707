
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import PersonListOverlay from '@/components/persons/search-overlay/PersonListOverlay.vue'
import { trace } from '@/services/log-client'

@Component({
      components: { PersonListOverlay }
    }
)
// TODO: Overlay (?)
export default class PersonCounterListItem extends Vue {
  @Prop()
  persons: Person[]

  @Prop()
  title: string

  showOverlay = false

  private showPersons() {
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'PersonCounterListItem.vue',
      group: this.title,
      sumPersons: this.persons.length
    })
  }
}
