
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class AppBar extends Vue {
  get username() {
    return this.$store.getters['auth/username']
  }

  get roleLive() {
    return this.$store.getters['auth/roleLive']
  }

  doLogout() {
    this.$store.dispatch('auth/logout').then(() => {
      this.$store.commit('personfile/prune')
      this.$store.commit('coursefile/prune')
      this.$store.commit('tenant/prune')
      this.$store.commit('userprofile/prune')
      this.$router.push('/login')
    })
  }
}
