export const system = {
  namespaced: true,
  state: {
    backendUnavailable: false
  },
  actions: {},
  mutations: {
    setBackendUnavailable(state, data) {
      state.backendUnavailable = data
    }
  },
  getters: {
    backendUnavailable: state => state.backendUnavailable
  }
}
