
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class ProfileEdit extends Vue {
  get username(): string {
    return this.$store.getters['auth/username']
  }
}
