import { http } from '@/services/http-api'

export const getMessages = (courseID: string) => http.get('/api/live/' + courseID)

export const sendMessage = (courseID: string, message: string) =>
  http.post('/api/live',
    {
      courseID: courseID,
      message: message
    })

export const deleteMessage = (messageID: string) => http.delete('/api/live/' + messageID)
