import { CSV_FIELD, CSV_FIELD_MAPPING, CSV_PERSON_MAPPING_SCHEMA } from '@/components/model'
import { CSVPERSON_MAPPING } from '@/components/schema/csv_person_mapping'
import { info } from '@/services/log-client'

function findMapping(field: CSV_FIELD, csvPropertyNames: string[]): CSV_FIELD_MAPPING {
  // OPTIONAL PROPERTIES: Wrong colum names (e.g. NachName) will set the field (name) to undefined
  // REQUIRED PROPERTIES: Will be ensured to be present, but can be null or empty

  const mapping = field.mappings
    .find(mapping => csvPropertyNames.includes(mapping.csvFieldname))

  if (field.required === true && !mapping) {
    throw new Error(`no mapping found for field ${field.name}`)
  }

  return mapping
}

export function createSchema(csvLines: any[]): CSV_PERSON_MAPPING_SCHEMA {
  const csvPropertyNames = Object.getOwnPropertyNames(csvLines[0])

  info({
    key: 'first_csv_row',
    firstCsvRow: `[${csvPropertyNames}]`
  })

  return {
    name: findMapping(CSVPERSON_MAPPING.name, csvPropertyNames),
    firstname: findMapping(CSVPERSON_MAPPING.firstname, csvPropertyNames),
    plz: findMapping(CSVPERSON_MAPPING.plz, csvPropertyNames),
    city: findMapping(CSVPERSON_MAPPING.city, csvPropertyNames),
    yearOfBirth: findMapping(CSVPERSON_MAPPING.yearOfBirth, csvPropertyNames),
    orgKey: findMapping(CSVPERSON_MAPPING.orgKey, csvPropertyNames),
    sdPflichtUntil: findMapping(CSVPERSON_MAPPING.sdPflichtUntil, csvPropertyNames),
    rank: findMapping(CSVPERSON_MAPPING.rank, csvPropertyNames),
    functionKey: findMapping(CSVPERSON_MAPPING.functionKey, csvPropertyNames),
    formationKey: findMapping(CSVPERSON_MAPPING.formationKey, csvPropertyNames),
    formationLabel: findMapping(CSVPERSON_MAPPING.formationLabel, csvPropertyNames),
    unit: findMapping(CSVPERSON_MAPPING.unit, csvPropertyNames),
    phone: findMapping(CSVPERSON_MAPPING.phone, csvPropertyNames)
  }
}
