
import { Component, Prop, Vue } from 'vue-property-decorator'
import PersonSearchList from '@/components/persons/search-overlay/PersonSearchList.vue'
import { PersonGroup } from '@/components/util/PersonsLodashUtil'

@Component({
  components: { PersonSearchList }
})
export default class PersonGroupOverlay extends Vue {
  @Prop()
  personGroups: PersonGroup[]

  @Prop()
  title: string

  get sum() {
    if (!this.personGroups) {
      return 0
    }
    return this.personGroups.map(g => g.persons.length).reduce((a, b) => a + b)
  }
}
