import { Course } from '@/components/model'
import _ from 'lodash'

export interface CourseGroup {
  key: string;
  courses: Course[];
}

export function groupCoursesByYear(courses: Course[]): CourseGroup[] {
  return _.chain(courses)
    .groupBy((course) => course.year)
    .map((value, key) => ({ key: key, courses: value }))
    .value()
}

export function groupCoursesByType(courses: Course[]): CourseGroup[] {
  return _.chain(courses)
    .filter(c => !!c.type)
    .groupBy((course) => course.type)
    .map((value, key) => ({ key: key, courses: value }))
    .value()
}

export function getCourseTypesDistinct(courses: Course[]): string[] {
  const types = courses.map(p => p.type)
  return [...new Set(types)].sort()
}

export function sumAchievedDays(courses: Course[]): number {
  const achievedDays = courses.map(c => c.achievedDays)
  return _.sum(achievedDays)
}

export function filterCoursesWithAchievedDays(courses: Course[]): Course[] {
  return courses.filter(c => c.achievedDays > 0)
}

export const byCourseNumber = (a: Course, b: Course) => a.courseNumber.localeCompare(b.courseNumber)
