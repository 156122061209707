import { CSV_FIELD_MAPPING, CSV_PERSON_MAPPING_SCHEMA, CsvPerson } from '@/components/model'
import { parseDate } from '@/components/csv-upload/DateParser'

function readString(fieldmapping: CSV_FIELD_MAPPING, csvLine: any): string {
  return fieldmapping ? csvLine[fieldmapping.csvFieldname] : null
}

function readDate(fieldmapping: CSV_FIELD_MAPPING, csvLine: any): Date {
  return fieldmapping ? parseDate(csvLine[fieldmapping.csvFieldname], fieldmapping.format) : null
}

export function createCsvPerson(csvLine: any, schema: CSV_PERSON_MAPPING_SCHEMA): CsvPerson {
  return {
    name: readString(schema.name, csvLine),
    firstname: readString(schema.firstname, csvLine),
    plz: readString(schema.plz, csvLine),
    city: readString(schema.city, csvLine),
    yearOfBirth: Number(readString(schema.yearOfBirth, csvLine)),
    orgKey: readString(schema.orgKey, csvLine),
    sdPflichtUntil: readDate(schema.sdPflichtUntil, csvLine),
    functionKey: readString(schema.functionKey, csvLine),
    rank: readString(schema.rank, csvLine),
    formationKey: readString(schema.formationKey, csvLine),
    formationLabel: readString(schema.formationLabel, csvLine),
    unit: readString(schema.unit, csvLine),
    phone: readString(schema.phone, csvLine)
  }
}
