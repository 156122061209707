
import { Component, Vue } from 'vue-property-decorator'
import TermsContent from '@/components/info/TermsContent.vue'

@Component({
    components: { TermsContent }
  }
)
export default class Terms extends Vue {
}
