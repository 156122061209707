
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import PersonListOverlay from '@/components/persons/search-overlay/PersonListOverlay.vue'
import { trace } from '@/services/log-client'

@Component({
    components: { PersonListOverlay }
  }
)
export default class PersonsCounterCard extends Vue {
  @Prop()
  persons!: Person[]

  @Prop()
  title: string

  showOverlay = false

  private showPersons() {
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'PersonsCounterCard.vue',
      group: this.title,
      sumPersons: this.persons.length
    })
  }
}
