
import { Component, Vue } from 'vue-property-decorator'
import Terms from '@/components/info/Terms.vue'

@Component({
  components: { Terms }
})
export default class Contact extends Vue {
  get email() {
    return this.$store.getters.email
  }

  // TODO: contact form

  private rating = 0

  get ratingMessage(): string {
    if (this.rating === 0) {
      return ''
    }
    if (this.rating === 1) {
      return 'why?'
    } else {
      return 'thank you'
    }
  }
}
