
import { Component, Vue } from 'vue-property-decorator'
import { CsvCourse, Tenant, UserProfile } from '@/components/model'
import Spinner from '@/components/Spinner.vue'
import TenantExpired from '@/components/TenantExpired.vue'
import CsvUploadCourses from '@/components/csv-upload-courses/CsvUploadCourses.vue'
import CoursesCharts from '@/views/courses/CoursesCharts.vue'
import AcceptTerms from '@/components/accept-terms/AcceptTerms.vue'

@Component({
  components: { AcceptTerms, CoursesCharts, CsvUploadCourses, TenantExpired, Spinner }
})
export default class Courses extends Vue {
  get loading() {
    return !this.tenant
  }

  get tenantValid(): boolean {
    return this.$store.getters['tenant/valid']
  }

  get tenant(): Tenant {
    return this.$store.getters['tenant/tenant']
  }

  get userprofile(): UserProfile {
    return this.$store.getters['userprofile/userprofile']
  }

  get acceptedTerms(): UserProfile {
    return this.$store.getters['userprofile/acceptedTerms']
  }

  get showHint() {
    return this.$store.getters['tenant/showHints']
  }

  get processing(): boolean {
    return this.$store.getters['coursefile/processing']
  }

  get courses(): CsvCourse[] {
    return this.$store.getters['coursefile/courses']
  }

  mounted() {
    if (this.tenantValid === null) {
      this.$store.dispatch('tenant/fetchValid')
    }

    if (!this.tenant) {
      this.$store.dispatch('tenant/fetchTenant')
    }

    if (!this.userprofile) {
      this.$store.dispatch('userprofile/fetchUserprofile')
    }
  }
}
