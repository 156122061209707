import VueChart from 'vue-chartjs'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

/*
 * Don't touch this magic shit
 *
 * - value is expected to be a number, saying the percentage
 * - creates the label: <value> '%'
 */
@Component
export default class BarChartDecorator extends Mixins(VueChart.Bar, VueChart.mixins.reactiveProp) {
  @Prop({ required: true, default: {} })
  declare public chartData: Chart.ChartData

  private handleClick(event: any, chartData: any) {
    if (chartData[0]) {
      this.$emit('on-click', chartData[0]._index)
    }
  }

  private options: Chart.ChartOptions = {
    legend: {
      // display: false
      position: 'bottom'
    },
    tooltips: {
      enabled: false
    },
    hover: {
      onHover(event: any, activeElements: Array<object>): any {
        event.target.style.cursor = activeElements.length ? 'pointer' : 'default'
      }
    },
    onClick: this.handleClick,
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        anchor: 'center',
        textAlign: 'center'
      }
    },
    scales: {
      xAxes: [{
        stacked: true,
        gridLines: {
          display: false
        }
      }],
      yAxes: [{
        stacked: true,
        ticks: {
          stepSize: 1,
          display: false
        },
        gridLines: {
          display: false
        }
      }]
    }
  }

  mounted() {
    this.addPlugin([ChartDataLabels])
    this.renderChart(this.chartData, this.options)
  }
}
