
import { Component, Vue } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class LicenseRenewDialog extends Vue {
  private createOrder() {
    this.$emit('on-create')
  }
}
