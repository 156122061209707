
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { PersonsBarchartBar, Person } from '@/components/model'
import { groupPersonsByRegional, groupPersonsByYearOfBirth } from '@/components/util/PersonsLodashUtil'
import BarChartDecorator from '@/components/chart-decorators/BarChartDecorator'
import PersonGroupOverlay from '@/components/persons/search-overlay/PersonGroupOverlay.vue'
import { InsightsColorPicker } from '@/components/util/InsightsColorPicker'
import { trace } from '@/services/log-client'

@Component({
    components: { PersonGroupOverlay, BarChartDecorator }
  }
)
export default class PersonsByYearBarChart extends Vue {
  @Prop()
  persons!: Person[]

  selectedGroups: PersonsBarchartBar = null

  showOverlay = false

  private showPersons(index: number) {
    this.selectedGroups = this.personsByYearOfBirthAndRegional[index]
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'PersonsByYearBarChart',
      group: this.selectedGroups.label,
      sumPersons: this.selectedGroups.sumPersons
    })
  }

  get personsByYearOfBirthAndRegional(): PersonsBarchartBar[] {
    return groupPersonsByYearOfBirth(this.persons)
      .map(group => ({
        label: group.key,
        persons: groupPersonsByRegional(group.persons, this.$store.getters['tenant/orgKey']),
        sumPersons: group.persons.length
      }))
  }

  get chartData(): Chart.ChartData {
    const colorPicker = new InsightsColorPicker() // TODO
    return {
      labels: this.personsByYearOfBirthAndRegional.map(p => [p.label, '(' + p.sumPersons + ')']),
      datasets: ['Regional', 'Überregional'].map(r => ({
        label: r,
        backgroundColor: colorPicker.next(),
        data: this.personsByYearOfBirthAndRegional.map(p => this.countPersonsWithRegional(p, r))
      }))
    }
  }

  private countPersonsWithRegional(bar: PersonsBarchartBar, key: string) {
    const byRegional = bar.persons.find(grp => grp.key === key)
    return byRegional ? byRegional.persons.length : undefined
  }
}
