
import { Component, Vue } from 'vue-property-decorator'
import { LiveMessage, Tenant } from '@/components/model'
import { deleteMessage, getMessages, sendMessage } from '@/services/live-client'
import { formatDistanceToNowGerman } from '@/components/util/datefns-util'
import Spinner from '@/components/Spinner.vue'
import TenantExpired from '@/components/TenantExpired.vue'

@Component({
  components: { TenantExpired, Spinner }
})
export default class Live extends Vue {
  private textfield = ''

  private messages: LiveMessage[] = []

  get loading() {
    return !this.tenant
  }

  get tenantValid(): boolean {
    return this.$store.getters['tenant/valid']
  }

  get tenant(): Tenant {
    return this.$store.getters['tenant/tenant']
  }

  get url() {
    return `${process.env.VUE_APP_LIVE_URL}/#/${this.tenant.livePath}`
  }

  private formatTimestamp(timestamp: string) {
    return formatDistanceToNowGerman(new Date(timestamp))
  }

  get roleLive() {
    return this.$store.getters['auth/roleLive']
  }

  send() {
    if (!this.textfield) {
      return
    }
    sendMessage(this.tenant.livePath, this.textfield)
      .then(() => {
        this.textfield = ''
        this.refreshFeed()
      })
  }

  deleteMsg(id: string) {
    deleteMessage(id)
      .then(() => this.refreshFeed())
  }

  mounted() {
    if (this.tenantValid === null) {
      this.$store.dispatch('tenant/fetchValid')
    }

    if (!this.tenant) {
      this.$store.dispatch('tenant/fetchTenant')
        .then(() => this.refreshFeed())
    } else {
      this.refreshFeed()
    }
  }

  private refreshFeed() {
    getMessages(this.tenant.livePath).then(res => {
      this.messages = res.data
    })
  }
}
