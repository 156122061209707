import { http } from '@/services/http-api'

export const isTenantValid = () => http.get('/api/tenant/valid')

export const getTenant = () => http.get('/api/tenant')

export const patchShowHints = (showHints: boolean) => http.patch('/api/tenant/showhints/' + showHints)

export const patchInactiveFormations = (formationKeys: string[]) =>
  http.patch('/api/tenant/inactiveFormations', { formationKeys })
