
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { PersonsBarchartBar, Person, Unit } from '@/components/model'
import { groupPersonsByEntlassung, groupPersonsByUnit } from '@/components/util/PersonsLodashUtil'
import BarChartDecorator from '@/components/chart-decorators/BarChartDecorator'
import { increment } from '@/components/util/ArrayUtil'
import PersonGroupOverlay from '@/components/persons/search-overlay/PersonGroupOverlay.vue'
import { UNITS } from '@/data/units'
import { trace } from '@/services/log-client'

@Component({
    components: { PersonGroupOverlay, BarChartDecorator }
  }
)
export default class EntlassungenByUnitBarChart extends Vue {
  @Prop()
  persons!: Person[]

  LENGTH = 12

  private dueYears = increment(new Date().getFullYear(), this.LENGTH)

  selectedGroups: PersonsBarchartBar = null

  showOverlay = false

  private showPersons(index: number) {
    this.selectedGroups = this.personsByDueAndUnit[index]
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'EntlassungenByUnitBarChart',
      group: this.selectedGroups.label,
      sumPersons: this.selectedGroups.sumPersons
    })
  }

  get personsByDueAndUnit(): PersonsBarchartBar[] {
    return groupPersonsByEntlassung(this.persons, this.dueYears)
      .map(group => ({
        label: group.key,
        persons: groupPersonsByUnit(group.persons),
        sumPersons: group.persons.length
      }))
  }

  get chartData(): Chart.ChartData {
    return {
      labels: this.personsByDueAndUnit.map(p => [p.label, '(' + p.sumPersons + ')']),
      datasets: UNITS.map(u => ({
        label: u.name.join('/'),
        backgroundColor: u.color,
        data: this.personsByDueAndUnit.map(p => this.createData(p, u))
      }))
    }
  }

  private createData(bar: PersonsBarchartBar, unit: Unit) {
    const byUnit = bar.persons.find(grp => unit.name.includes(grp.key))
    return byUnit ? byUnit.persons.length : undefined
  }
}
