
import { Component, Prop, Vue } from 'vue-property-decorator'
import PersonSearchList from '@/components/persons/search-overlay/PersonSearchList.vue'
import { Person } from '@/components/model'

@Component({
  components: { PersonSearchList }
})
export default class PersonListOverlay extends Vue {
  @Prop()
  persons: Person[]

  @Prop()
  title: string
}
