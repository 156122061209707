
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'

@Component({
  components: {}
})
export default class PersonSearchCard extends Vue {
  @Prop()
  person!: Person
}
