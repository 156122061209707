
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { PersonsBarchartBar, Person } from '@/components/model'
import {
  getFunctionsDistinct,
  groupPersonsByFunction,
  groupPersonsByYearOfBirth
} from '@/components/util/PersonsLodashUtil'
import BarChartDecorator from '@/components/chart-decorators/BarChartDecorator'
import { InsightsColorPicker } from '@/components/util/InsightsColorPicker'
import PersonGroupOverlay from '@/components/persons/search-overlay/PersonGroupOverlay.vue'
import { trace } from '@/services/log-client'

@Component({
    components: {
      PersonGroupOverlay,
      BarChartDecorator
    }
  }
)
export default class PersonsByFunctionBarChart extends Vue {
  @Prop()
  persons!: Person[]

  @Prop()
  subtitle: string

  selectedGroups: PersonsBarchartBar = null

  showOverlay = false

  private showPersons(index: number) {
    this.selectedGroups = this.personsByByYearOfBirthAndFunction[index]
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: 'PersonsByFunctionBarChart',
      group: this.selectedGroups.label,
      sumPersons: this.selectedGroups.sumPersons
    })
  }

  get personsByByYearOfBirthAndFunction(): PersonsBarchartBar[] {
    return groupPersonsByYearOfBirth(this.persons)
      .map(group => ({
        label: group.key,
        persons: groupPersonsByFunction(group.persons),
        sumPersons: group.persons.length
      }))
  }

  get chartData(): Chart.ChartData {
    const colorPicker = new InsightsColorPicker()
    return {
      labels: this.personsByByYearOfBirthAndFunction.map(p => [p.label, '(' + p.sumPersons + ')']),
      datasets: getFunctionsDistinct(this.persons).map(f => ({
        label: f,
        backgroundColor: colorPicker.next(),
        data: this.personsByByYearOfBirthAndFunction.map(p => this.countPersonsWithFunction(p, f))
      }))
    }
  }

  private countPersonsWithFunction(bar: PersonsBarchartBar, functionKey: string) {
    const byFunction = bar.persons.find(grp => grp.key === functionKey)
    return byFunction ? byFunction.persons.length : undefined
  }
}
