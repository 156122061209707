import { warn } from '@/services/log-client'
import { City } from '@/components/model'

export function guessOrg(plz: string, ort: string, cities: City[]): string {
  const result = cities.filter(c => c.plz === plz)
  if (result.length > 1) {
    const byExactName = result.filter(city => city.name === ort)

    if (byExactName[0]) {
      return byExactName[0].orgKey
    } else {
      const bySimilarName = result.filter(city => city.name.includes(ort) || ort.includes(city.name))

      if (bySimilarName[0]) {
        return bySimilarName[0].orgKey
      }
      warn({
        key: 'multiple_cities',
        plz: plz,
        using: result[0].name,
        msg: `multiple cities found for plz: ${plz} using any: ${result[0].name}`
      })
    }
  }

  if (result[0]) {
    return result[0].orgKey
  }

  warn({ key: 'unknown_plz', plz: plz, msg: `no city found for plz: ${plz} ==> assigning localOrgKey null` })
  return null
}
