import VueChart from 'vue-chartjs'
import { Component, Mixins, Prop } from 'vue-property-decorator'
import Chart from 'chart.js'
import ChartDataLabels from 'chartjs-plugin-datalabels'

/*
 * Don't touch this magic shit
 *
 * - value is expected to be a number, saying the percentage
 * - creates the label: <value> '%'
 */
@Component
export default class DonutChartDecorator extends Mixins(VueChart.Doughnut, VueChart.mixins.reactiveProp) {
  @Prop({ required: true, default: {} })
  declare public chartData: Chart.ChartData

  private handleClick(event: any, chartData: any) {
    if (chartData[0]) {
      this.$emit('on-click', chartData[0]._index)
    }
  }

  private handleLegendClick(event: any, legendItem: any) {
    // noop
  }

  private options: Chart.ChartOptions = {
    legend: {
      display: false
    },
    layout: {},
    tooltips: {
      callbacks: {
        label(tooltipItem: Chart.ChartTooltipItem, data: Chart.ChartData): string | string[] {
          return data.labels[tooltipItem.index] + ''
        }
      }
    },
    hover: {
      onHover(event: any, activeElements: Array<object>): any {
        event.target.style.cursor = activeElements.length ? 'pointer' : 'default'
      }
    },
    onClick: this.handleClick,
    plugins: {
      datalabels: {
        color: 'white',
        font: {
          weight: 'bold'
        },
        // backgroundColor: 'black',
        anchor: 'center',
        textAlign: 'center',
        formatter: (value: number) => {
          let sum = 0

          this.chartData.datasets[0].data.forEach(d => {
            sum = sum + d
          })

          if ((100 / sum * value) < 3) {
            return ''
          }

          return value
        }
      }
    }
  }

  mounted() {
    this.addPlugin([ChartDataLabels])
    this.renderChart(this.chartData, this.options)
  }
}
