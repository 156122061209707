export const personfile = {
  namespaced: true,
  state: {
    file: null,
    persons: [],
    orgFormations: [],
    showError: false,
    processing: false
  },
  mutations: {
    setFile(state, file) {
      state.file = file
    },
    setPersons(state, persons) {
      state.persons = persons
    },
    setOrgFormations(state, orgFormations) {
      state.orgFormations = orgFormations
    },
    setShowError(state, showError) {
      state.showError = showError
    },
    setProcessing(state, processing) {
      state.processing = processing
    },
    prune(state) {
      state.file = null
      state.persons = []
      state.orgFormations = []
      state.showError = false
      state.processing = false
    }
  },
  getters: {
    file: state => state.file,
    persons: state => state.persons,
    orgFormations: state => state.orgFormations,
    showError: state => state.showError,
    processing: state => state.processing
  }
}
