
import { Component, Vue, Watch } from 'vue-property-decorator'
import { error, info, trace } from '@/services/log-client'
import { UserProfile } from '@/components/model'
import { parseFile } from '@/components/csv-upload-courses/CoursesCsvParser'

@Component
export default class CsvUploadCourses extends Vue {
  selectedEncoding = this.userprofile?.preferredEncoding || 'l2'

  get encodings() {
    return ['l2', 'l3', 'utf-8']
  }

  get email() {
    return this.$store.getters.email
  }

  get file() {
    return this.$store.getters['coursefile/file']
  }

  set file(file: File) {
    this.$store.commit('coursefile/setFile', file)
  }

  get showError() {
    return this.$store.getters['coursefile/showError']
  }

  get userprofile(): UserProfile {
    return this.$store.getters['userprofile/userprofile']
  }

  get processing() {
    return this.$store.getters['coursefile/processing']
  }

  @Watch('selectedEncoding')
  onEncodingChange(newValue: string, oldValue: string) {
    info({ key: `changed encoding from ${oldValue} to ${newValue}` })
    this.handleFileUpload(this.file)
    this.$store.dispatch('userprofile/patchPreferredEncoding', newValue)
  }

  handleFileUpload(file: File) {
    this.$store.commit('coursefile/setProcessing', true)
    this.$store.commit('coursefile/setShowError', false)
    this.$store.commit('coursefile/setCourses', [])

    info({
      key: 'coursefile_uploaded',
      fileName: file.name
    })

    parseFile(file, this.selectedEncoding)
      .then(courses => {
        this.$store.commit('coursefile/setShowError', false)
        this.$store.commit('coursefile/setCourses', courses)

        trace({
          key: 'coursefile_parse_success',
          numCourses: courses.length,
          msg: 'successfully parsed course file'
        })

        this.$store.commit('coursefile/setProcessing', false)
      })
      .catch(err => {
        this.$store.commit('coursefile/setProcessing', false)
        this.$store.commit('coursefile/setShowError', true)
        error({ key: 'coursefile_error', stacktrace: err.stack })
      })
  }
}
