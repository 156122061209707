
import { Component, Vue } from 'vue-property-decorator'
import CsvUpload from '@/components/csv-upload/CsvUpload.vue'
import Spinner from '@/components/Spinner.vue'
import TenantExpired from '@/components/TenantExpired.vue'
import { City, Person, Tenant, UserProfile } from '@/components/model'
import AcceptTerms from '@/components/accept-terms/AcceptTerms.vue'
import { filterOrg } from '@/components/util/PersonsLodashUtil'

@Component({
  components: {
    AcceptTerms,
    TenantExpired,
    Spinner,
    CsvUpload
  }
})
export default class Personal extends Vue {
  get loading() {
    return !this.tenant || !this.cities || !this.orgs || !this.userprofile
  }

  get tenantValid(): boolean {
    return this.$store.getters['tenant/valid']
  }

  get cities(): City[] {
    return this.$store.getters['city/cities']
  }

  get orgs(): City[] {
    return this.$store.getters['org/orgs']
  }

  get tenant(): Tenant {
    return this.$store.getters['tenant/tenant']
  }

  get userprofile(): UserProfile {
    return this.$store.getters['userprofile/userprofile']
  }

  get acceptedTerms(): UserProfile {
    return this.$store.getters['userprofile/acceptedTerms']
  }

  get showHint() {
    return this.$store.getters['tenant/showHints']
  }

  get processing(): boolean {
    return this.$store.getters['personfile/processing']
  }

  get persons(): Person[] {
    return this.$store.getters['personfile/persons']
  }

  get orgPersons(): Person[] {
    return filterOrg(this.$store.getters['personfile/persons'], this.$store.getters['tenant/orgKey'])
  }

  mounted() {
    if (this.tenantValid === null) {
      this.$store.dispatch('tenant/fetchValid')
    }

    if (!this.tenant) {
      this.$store.dispatch('tenant/fetchTenant')
    }

    if (!this.userprofile) {
      this.$store.dispatch('userprofile/fetchUserprofile')
    }

    if (!this.orgs) {
      this.$store.dispatch('org/fetchOrgs')
    }

    if (!this.cities) {
      this.$store.dispatch('city/fetchCities')
    }
  }
}
