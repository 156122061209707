
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import PersonSearchCard from '@/components/persons/search/PersonSearchCard.vue'

@Component({
  components: { PersonSearchCard }
})
export default class PersonSearchCards extends Vue {
  @Prop()
  persons!: Person[]

  private LIMIT = 100

  get items(): Person[] {
    return this.persons
      .slice(0, this.LIMIT)
  }
}
