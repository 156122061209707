// Unit
import { Unit } from '@/components/model'
import {
  ABMER_DARKEN_2,
  BLUE_DARKEN_1,
  BLUE_GREY_DARKEN_1,
  DEEP_ORANGE_DARKEN_4,
  GREEN_DARKEN_2
} from '@/components/AppColors'
import { error } from '@/services/log-client'
import { getRandomColor } from '@/components/util/InsightsColorPicker'

export const KDO: Unit = { name: ['Führung'], color: '#212121' }
export const FUST: Unit = { name: ['Führungsunterstützung'], color: BLUE_GREY_DARKEN_1 }
export const BETR: Unit = { name: ['Betreuung'], color: ABMER_DARKEN_2 }
export const UST: Unit = { name: ['Unterstützung', 'Technische Hilfe'], color: DEEP_ORANGE_DARKEN_4 }
export const KGS: Unit = { name: ['Kulturgüterschutz'], color: BLUE_DARKEN_1 }
export const LOG: Unit = { name: ['Logistik'], color: GREEN_DARKEN_2 }

export const UNITS: Unit[] = [
  UST, FUST, BETR, LOG, KDO, KGS
]

export function getUnitColor(unitName: string): string {
  const unit = UNITS.find(u => u.name.includes(unitName))
  if (!unit) {
    error({ key: 'unit_does_not_exist', unitName: unitName })
    return getRandomColor()
  }
  return unit.color
}
