
import { Component, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import { filterOrg, groupPersonsByFormation, PersonGroup } from '@/components/util/PersonsLodashUtil'
import CsvUpload from '@/components/csv-upload/CsvUpload.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import PersonSearchList from '@/components/persons/search-overlay/PersonSearchList.vue'
import PersonsByFunctionDonutChart from '@/components/persons/charts/PersonsByFunctionDonutChart.vue'
import PersonsByFunctionBarChart from '@/components/persons/charts/PersonsByFunctionBarChart.vue'
import PrognosisByFunctionBarChart from '@/components/persons/charts/PrognosisByFunctionBarChart.vue'
import EntlassungenByFunctionBarChart from '@/components/persons/charts/EntlassungenByFunctionBarChart.vue'
import { trace } from '@/services/log-client'

@Component({
  components: {
    EntlassungenByFunctionBarChart,
    PrognosisByFunctionBarChart,
    PersonsByFunctionBarChart,
    PersonsByFunctionDonutChart,
    ScrollToTop,
    CsvUpload,
    PersonSearchList
  }
})
export default class FormationCharts extends Vue {
  private selectedFormationKey = null

  get orgPersons(): Person[] {
    return filterOrg(this.$store.getters['personfile/persons'], this.$store.getters['tenant/orgKey'])
  }

  get personsByFormation(): PersonGroup[] {
    return groupPersonsByFormation(this.orgPersons)
  }

  get selectedFormation(): PersonGroup {
    return this.personsByFormation.find(g => g.key === this.selectedFormationKey)
  }

  selectFormation(key: string) {
    this.selectedFormationKey = key
    trace({ key: 'select_formation', route: this.$route.path, formation: key })
  }

  private getFormationLabel(formationKey: string): string {
    return this.$store.getters['personfile/orgFormations'].find(f => f.key === formationKey).label
  }
}
