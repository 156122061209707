
import { Component, Vue } from 'vue-property-decorator'
import Contact from '@/views/info/Contact.vue'
import { trace } from '@/services/log-client'

@Component({
  components: { Contact }
})
export default class Login extends Vue {
  username = ''
  password = ''
  showErrorMessage = false
  showPassword = false

  created() {
    this.$store.dispatch('auth/logout')
  }

  get email() {
    return this.$store.getters.email
  }

  doLogin() {
    this.$store.dispatch('auth/login', {
      username: this.username,
      password: this.password
    })
      .then(() => {
        this.$router.push('/')
        trace({ key: 'login' })
      })
      .catch(() => {
        this.showErrorMessage = true
      })
  }
}
