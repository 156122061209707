
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Course } from '@/components/model'
import { byCourseNumber } from '@/components/util/CoursesLodashUtil'

@Component
export default class CourseSearchList extends Vue {
  @Prop()
  courses!: Course[]

  get items(): Course[] {
    return this.courses
      .sort(byCourseNumber).reverse()
  }
}
