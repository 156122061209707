
import { Component, Vue, Watch } from 'vue-property-decorator'
import { parseFile } from '@/components/csv-upload/PersonCsvParser'
import { error, info } from '@/services/log-client'
import { getFormationsDistinct } from '@/components/util/PersonsLodashUtil'
import { UserProfile } from '@/components/model'

@Component
export default class CsvUpload extends Vue {
  selectedEncoding = this.userprofile?.preferredEncoding || 'l2'

  get encodings() {
    return ['l2', 'l3', 'utf-8']
  }

  get email() {
    return this.$store.getters.email
  }

  get file() {
    return this.$store.getters['personfile/file']
  }

  set file(file: File) {
    this.$store.commit('personfile/setFile', file)
  }

  get showHint() {
    return this.$store.getters['tenant/showHints']
  }

  get showError() {
    return this.$store.getters['personfile/showError']
  }

  get userprofile(): UserProfile {
    return this.$store.getters['userprofile/userprofile']
  }

  get processing() {
    return this.$store.getters['personfile/processing']
  }

  @Watch('selectedEncoding')
  onEncodingChange(newValue: string, oldValue: string) {
    info({ key: `changed encoding from ${oldValue} to ${newValue}` })
    this.handleFileUpload(this.file)
    this.$store.dispatch('userprofile/patchPreferredEncoding', newValue)
  }

  handleFileUpload(file: File) {
    this.$store.commit('personfile/setProcessing', true)
    this.$store.commit('personfile/setShowError', false)
    this.$store.commit('personfile/setPersons', [])

    parseFile(file, this.selectedEncoding, this.$store.getters['city/cities'])
      .then(persons => {
        this.$store.commit('personfile/setShowError', false)

        this.$store.commit('personfile/setPersons', persons)

        info({
          key: 'parse_success',
          numPersons: persons.length,
          msg: 'successfully parsed file'
        })

        this.$store.commit('personfile/setOrgFormations',
          getFormationsDistinct(persons, this.$store.getters['tenant/orgKey'], this.$store.getters['tenant/inactiveFormations']))

        this.$store.commit('personfile/setProcessing', false)
      })
      .catch(err => {
        this.$store.commit('personfile/setProcessing', false)
        this.$store.commit('personfile/setShowError', true)
        error({ key: 'parse_error', stacktrace: err.stack })
      })
  }
}
