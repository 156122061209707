import { Course, CsvCourse } from '@/components/model'

export function createCourse(csvCourse: CsvCourse): Course {
  return Object.assign({}, {
    status: csvCourse.status,
    courseNumber: csvCourse.courseNumber,
    name: csvCourse.name,
    type: csvCourse.type,
    bzgArt: csvCourse.bzgArt,
    participationType: csvCourse.participationType,
    functionAbbr: csvCourse.functionAbbr,
    eoCode: csvCourse.eoCode,
    location: csvCourse.location,
    refNr: csvCourse.refNr,
    startDate: csvCourse.startDate,
    startTime: csvCourse.startTime,
    endDate: csvCourse.endDate,
    endTime: csvCourse.endTime,
    durationDays: csvCourse.durationDays,
    achievedDays: Number(csvCourse.achievedDays),
    year: Number(csvCourse.startDate.substring(6)) // TODO
  })
}
