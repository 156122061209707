
import { Component, Prop, Vue } from 'vue-property-decorator'
import PersonSearchList from '@/components/persons/search-overlay/PersonSearchList.vue'
import CourseSearchList from '@/components/courses/overlay/CourseSearchList.vue'
import { CourseGroup, sumAchievedDays } from '@/components/util/CoursesLodashUtil'
import { Course } from '@/components/model'

@Component({
  components: { CourseSearchList, PersonSearchList }
})
export default class CourseGroupOverlay extends Vue {
  @Prop()
  courseGroups: CourseGroup[]

  @Prop()
  title: string

  get sum() {
    if (!this.courseGroups) {
      return 0
    }
    return this.courseGroups.map(g => sumAchievedDays(g.courses)).reduce((a, b) => a + b)
  }

  sumAchievedDays(courses: Course[]): number {
    return sumAchievedDays(courses)
  }
}
