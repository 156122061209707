
import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class Spinner extends Vue {
  @Prop()
  label: string

  get backendUnavailable(): boolean {
    return this.$store.getters['system/backendUnavailable']
  }
}
