import { CSV_COURSE_MAPPING_SCHEMA, CSV_FIELD_MAPPING, CsvCourse } from '@/components/model'

function readString(fieldmapping: CSV_FIELD_MAPPING, csvLine: any): string {
  return fieldmapping ? csvLine[fieldmapping.csvFieldname] : null
}

export function createCsvCourse(csvLine: any, schema: CSV_COURSE_MAPPING_SCHEMA): CsvCourse {
  return {
    status: readString(schema.status, csvLine),
    courseNumber: readString(schema.courseNumber, csvLine),
    name: readString(schema.name, csvLine),
    type: readString(schema.type, csvLine),
    bzgArt: readString(schema.bzgArt, csvLine),
    participationType: readString(schema.participationType, csvLine),
    functionAbbr: readString(schema.functionAbbr, csvLine),
    eoCode: readString(schema.eoCode, csvLine),
    location: readString(schema.location, csvLine),
    refNr: readString(schema.refNr, csvLine),
    startDate: readString(schema.startDate, csvLine),
    startTime: readString(schema.startTime, csvLine),
    endDate: readString(schema.endDate, csvLine),
    endTime: readString(schema.endTime, csvLine),
    durationDays: readString(schema.durationDays, csvLine),
    achievedDays: readString(schema.achievedDays, csvLine)
  }
}
