import { http2 } from '@/services/http-api'

export const trace = (args: object) => {
  http2.post('/api/log/trace', args)
}

export const info = (args: object) => {
  console.log(args)
  http2.post('/api/log/info', args)
}

export const warn = (args: object) => {
  console.log(args)
  http2.post('/api/log/warn', args)
}

export const error = (args: object) => {
  console.log(args)
  http2.post('/api/log/error', args)
}
