import Vue from 'vue'
import VueRouter, { RouteConfig } from 'vue-router'
import PersonSearch from '@/views/persons/PersonSearch.vue'
import Contact from '@/views/info/Contact.vue'
import Login from '@/views/Login.vue'
import { JWT_KEY } from '@/components/constants'
import ProfileView from '@/views/Profile.vue'
import OrgCharts from '@/views/persons/OrgCharts.vue'
import FormationCharts from '@/views/persons/FormationCharts.vue'
import UnitCharts from '@/views/persons/UnitCharts.vue'
import PersonalSettings from '@/views/persons/PersonalSettings.vue'
import Personal from '@/views/Personal.vue'
import Terms from '@/views/info/TermsView.vue'
import Live from '@/views/Live.vue'
import License from '@/views/profile/License.vue'
import ProfileEdit from '@/views/profile/ProfileEdit.vue'
import PasswordChange from '@/views/profile/PasswordChange.vue'
import Example from '@/views/info/Example.vue'
import { trace } from '@/services/log-client'
import Courses from '@/views/Courses.vue'

Vue.use(VueRouter)

const routes: Array<RouteConfig> = [
  {
    path: '/',
    redirect: '/personal/org'
  },
  {
    path: '/login',
    name: 'Login',
    component: Login
  },
  {
    path: '/contact',
    name: 'Contact',
    component: Contact
  },
  {
    path: '/terms',
    name: 'Terms',
    component: Terms
  },
  {
    path: '/example',
    name: 'Example',
    component: Example
  },
  {
    path: '/profile',
    component: ProfileView,
    children: [
      {
        path: 'edit',
        name: 'ProfileEdit',
        component: ProfileEdit
      },
      {
        path: 'passwordchange',
        name: 'PasswordChange',
        component: PasswordChange
      },
      {
        path: 'license',
        name: 'License',
        component: License
      }
    ]
  },
  {
    path: '/personal',
    component: Personal,
    redirect: '/personal/org',
    children: [
      {
        path: 'search',
        name: 'PersonSearch',
        component: PersonSearch
      },
      {
        path: 'org',
        name: 'OrgCharts',
        component: OrgCharts
      },
      {
        path: 'units',
        name: 'UnitCharts',
        component: UnitCharts
      },
      {
        path: 'formations',
        name: 'FormationCharts',
        component: FormationCharts
      },
      {
        path: 'settings',
        name: 'PersonalSettings',
        component: PersonalSettings
      }
    ]
  },
  {
    path: '/courses',
    name: 'Courses',
    component: Courses
  },
  {
    path: '/live',
    name: 'Live',
    component: Live
  }
]

const router = new VueRouter({
  routes,
  scrollBehavior() {
    return { x: 0, y: 0 }
  }
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/login']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem(JWT_KEY)

  if (authRequired && !loggedIn) {
    next('/login')
  } else if (to.path === '/login' && loggedIn) {
    next('/')
  } else {
    next()
  }
})

router.afterEach(to => {
  const loggedIn = localStorage.getItem(JWT_KEY)

  if (loggedIn) {
    trace({
      key: 'trace_route',
      route: to.path
    })
  }
})

export default router
