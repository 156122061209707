import { Formation, Person, Tenant } from '@/components/model'
import _ from 'lodash'
import { isAfter } from 'date-fns'
import { getRankOrder } from '@/data/ranks'

export interface PersonGroup {
  key: string;
  persons: Person[];
}

export interface PersonGroup2 {
  key: string;
  persons: Person[];
  label: string;
  color: string;
}

// sort functions

export const byNumberOfPersons = (a: PersonGroup, b: PersonGroup) => b.persons.length - a.persons.length

export const byRank = (a: Person, b: Person) => getRankOrder(a.rank) - getRankOrder(b.rank)

/*
 * - group by region
 * - order by size of group
 * - always put own org first
 */
export function groupPersonsLocalOrg(persons: Person[], profile: Tenant): PersonGroup[] {
  return _.chain(persons)
    .groupBy((person) => person.localOrgKey)
    .map((value, key) => ({ key: key, persons: value }))
    .sortBy([
      (group) => group.key === profile.orgKey,
      (group) => group.key !== 'null', // sortBy makes a string out of it
      (group) => group.persons.length
    ]).reverse()
    .value()
}

function filterPersonsByActiveInYear(persons: Person[], dueYear: number): Person[] {
  const dueDate = new Date()
  dueDate.setFullYear(dueYear, 11, 30) // month indexing starts at 0
  dueDate.setHours(12)
  return persons.filter(p => isAfter(p.sdPflichtUntil, dueDate))
}

export function groupPersonsBySDPflicht(persons: Person[], dueYears: number[]): PersonGroup[] {
  return dueYears
    .map(dueYear => ({ key: String(dueYear), persons: filterPersonsByActiveInYear(persons, dueYear) }))
}

export function groupPersonsByEntlassung(persons: Person[], dueYears: number[]): PersonGroup[] {
  return dueYears.map(dueYear => ({
    key: `${dueYear}`,
    persons: persons.filter(p => p.sdPflichtUntil.getFullYear() === dueYear)
  }))
}

export function sortBySizeAndLimit(groups: PersonGroup[], limit: number): PersonGroup[] {
  const sorted = groups.sort(byNumberOfPersons)
  const sonstige = {
    key: 'Sonstige',
    persons: sorted.slice(limit).flatMap(g => g.persons)
  }
  return [...sorted.slice(0, limit), sonstige]
}

export function groupPersonsByCity(persons: Person[]): PersonGroup[] {
  return _.chain(persons)
    .groupBy((person) => person.city)
    .map((value, key) => ({ key: key, persons: value }))
    .value()
}

export function groupPersonsByYearOfBirth(persons: Person[]): PersonGroup[] {
  return _.chain(persons)
    .groupBy((person) => person.yearOfBirth)
    .map((value, key) => ({ key: key, persons: value }))
    .value()
}

export function groupPersonsByFunction(persons: Person[]): PersonGroup[] {
  return _.chain(persons)
    .groupBy((person) => person.functionKey)
    .map((value, key) => ({ key: key, persons: value }))
    .value()
    .sort((a, b) => a.key.localeCompare(b.key))
}

export function groupPersonsByRank(persons: Person[]): PersonGroup[] {
  return _.chain(persons)
    .groupBy((person) => person.rank)
    .map((value, key) => ({ key: key, persons: value }))
    .value()
}

export function groupPersonsByUnit(persons: Person[]): PersonGroup[] {
  return _.chain(persons)
    .filter(p => !!p.unit)
    .groupBy((person) => person.unit)
    .map((value, key) => ({ key: key, persons: value }))
    .value()
}

export function filterUnit(persons: Person[], unitKey: string) {
  return persons
    .filter(p => !!p.unit)
    .filter(p => p.unit === unitKey)
}

export function filterOrg(persons: Person[], orgKey: string): Person[] {
  return persons.filter(person => person.orgKey === orgKey)
}

function isActive(person: Person, inactiveFormations: string[]) {
  return !inactiveFormations.includes(person.formationKey)
}

export function filterOrgActive(persons: Person[], tenant: Tenant): Person[] {
  return filterOrg(persons, tenant.orgKey)
    .filter(p => isActive(p, tenant.inactiveFormations))
}

export function filterOrgInactive(persons: Person[], tenant: Tenant): Person[] {
  return filterOrg(persons, tenant.orgKey)
    .filter(p => !isActive(p, tenant.inactiveFormations))
}

// TODO: move this to person?
export function regional(person: Person, orgKey: string): string {
  return person.localOrgKey === orgKey ? 'Regional' : 'Überregional'
}

export function groupPersonsByRegional(persons: Person[], orgKey: string): PersonGroup[] {
  return _.chain(persons)
    .groupBy((person) => regional(person, orgKey))
    .map((value, key) => ({ key: key, persons: value }))
    .sort((a, b) => a.key.localeCompare(b.key))
    .value()
}

export function groupPersonsByFormation(persons: Person[]): PersonGroup[] {
  return _.chain(persons)
    .groupBy((person) => person.formationKey)
    .map((value, key) => ({ key: key, persons: value }))
    .sort((a, b) => a.key.localeCompare(b.key))
    .value()
}

export function getFunctionsDistinct(persons: Person[]) {
  const functions = persons.map(p => p.functionKey)
  return [...new Set(functions)].sort()
}

export function getFormationKeysDistinct(persons: Person[]): string[] {
  const formations = persons.map(p => p.formationKey)
  return [...new Set(formations)].sort()
}

export function getFormationsDistinct(persons: Person[], orgKey: string, inactiveFormations: string[]): Formation[] {
  const formations = filterOrg(persons, orgKey)
    .map(p => ({
      key: p.formationKey,
      label: p.formationLabel,
      active: !inactiveFormations.includes(p.formationKey)
    }))
  return [...new Set(formations)]
}

/*
export function getUnitsDistinct(persons: Person[], orgKey: string): Unit[] {
  const units = filterOrg(persons, orgKey)
    .map(p => p.unit)

  return [...new Set(units)]
    .sort((a, b) => a.localeCompare(b))
    .map(u => ({
      name: u,
      color: getUnitColor(u)
    }))
}
 */

export function getRanksDistinct(persons: Person[]): string[] {
  const ranks = persons.map(p => p.rank)
  return [...new Set(ranks)]
    .sort((a, b) => getRankOrder(a) - getRankOrder(b))
}
