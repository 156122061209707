
import Vue from 'vue'
import AppBar from '@/components/AppBar.vue'
import { http } from '@/services/http-api'
import { error, trace } from '@/services/log-client'

export default Vue.extend({
  name: 'App',
  components: { AppBar },

  computed: {
    loggedIn: function () {
      return this.$store.getters['auth/loggedIn']
    }
  },

  created: function () {
    /*
    window.addEventListener('click', event => {
      console.log('click')
      console.log(event)
    })
     */

    const logout = () => this.$store.dispatch('auth/logout')
    const redirect = (path) => this.$router.push(path)
    const isLoggedIn = () => this.$store.getters['auth/loggedIn']
    const showUnavailable = (value: boolean) => {
      this.showSystemUnavailable = value
    }

    const responseInterceptor = (response) => {
      this.$store.commit('system/setBackendUnavailable', false)
      showUnavailable(false)
      return response
    }

    const errorInterceptor = (err) => {
      error({ key: 'http_error', stacktrace: err.stack })

      if (!err.response) {
        this.$store.commit('system/setBackendUnavailable', true)
        showUnavailable(true)
      }

      if ((err.response && (err.response.status === 401 || err.response.status === 403)) && isLoggedIn()) {
        console.log('token expired. logging out user.')
        return logout().then(redirect('/login'))
      }

      return Promise.reject(err)
    }

    http.interceptors.response.use(responseInterceptor, errorInterceptor)

    trace({ key: 'page_load', browser: navigator.userAgent })
  },

  data: () => ({
    showSystemUnavailable: false
  })
})
