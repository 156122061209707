import { parse, ParseResult } from 'papaparse'
import { Course, CSV_COURSE_MAPPING_SCHEMA, CsvCourse } from '@/components/model'
import { info } from '@/services/log-client'
import { createSchema } from '@/components/csv-upload-courses/CsvCourseSchemaCreator'
import { createCsvCourse } from '@/components/csv-upload-courses/CsvCourseCreator'
import { createCourse } from '@/components/csv-upload-courses/CourseCreator'

function validate(csvCourse: CsvCourse) {
  return !!csvCourse.name // TODO
}

function createCourses(csvLines: any[]): Promise<Course[]> {
  // MAPPING ---[required, select fieldmapping]---> SCHEMA ---> CsvPerson ---[filter, add data]---> Person

  let schema: CSV_COURSE_MAPPING_SCHEMA

  try {
    schema = createSchema(csvLines)
    info({
      key: 'schema_created',
      schema: JSON.stringify(schema)
    })
  } catch (err) {
    return Promise.reject(err)
  }

  const courses: Course[] = csvLines
    .map(line => createCsvCourse(line, schema))
    .filter(csvCourse => validate(csvCourse))
    .map(csvCourse => createCourse(csvCourse))

  return new Promise<Course[]>(function (resolve, reject) {
    if (courses.length > 0) {
      resolve(courses)
    } else {
      reject(new Error())
    }
  })
}

export function parseFile(csv: File, encoding: string): Promise<Course[]> {
  if (!csv) {
    return Promise.resolve([])
  }

  return new Promise<Course[]>((resolve, reject) => {
    parse<File>(
      csv,
      {
        header: true,
        worker: true,
        skipEmptyLines: 'greedy',
        encoding: encoding, // https://stackoverflow.com/questions/40790042/filereader-which-encodings-are-supported
        complete(results: ParseResult<object>): void {
          createCourses(results.data)
            .then(persons => resolve(persons))
            .catch(noPersonsError => reject(noPersonsError)) // courses.length == 0, or malformed csv
        }
      })
  })
}
