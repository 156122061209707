
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import { byRank } from '@/components/util/PersonsLodashUtil'

@Component
export default class PersonSearchList extends Vue {
  @Prop()
  persons!: Person[]

  get items(): Person[] {
    return this.persons
      .sort(byRank).reverse()
  }
}
