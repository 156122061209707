
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { PersonGroup2 } from '@/components/util/PersonsLodashUtil'
import PersonListOverlay from '@/components/persons/search-overlay/PersonListOverlay.vue'
import DonutChartDecorator from '@/components/chart-decorators/DonutChartDecorator'
import { trace } from '@/services/log-client'

@Component({
  components: { DonutChartDecorator, PersonListOverlay }
})
export default class PersonsDonutChart extends Vue {
  @Prop()
  personGroups!: PersonGroup2[]

  @Prop()
  title!: string

  @Prop()
  subtitle: string

  @Prop()
  chart!: string // logging

  showAll = false

  LIMIT = 6

  selectedGroup: PersonGroup2 = null

  showOverlay = false

  private showPersons(index: number) {
    this.selectedGroup = this.personGroups[index]
    this.showOverlay = true
    trace({
      key: 'overlay',
      route: this.$route.path,
      component: this.chart,
      group: this.selectedGroup.label,
      sumPersons: this.selectedGroup.persons.length
    })
  }

  get chartData(): Chart.ChartData {
    return this.createChartData(this.personGroups)
  }

  get limitedPersonGroups() {
    if (this.showAll) {
      return this.personGroups
    }
    return this.personGroups.slice(0, this.LIMIT)
  }

  private createChartData(groups: PersonGroup2[]): Chart.ChartData {
    return {
      labels: groups.map(g => `${g.label} (${g.persons.length})`),
      datasets: [
        {
          backgroundColor: groups.map(g => g.color),
          data: groups.map(g => g.persons.length)
        }
      ]
    }
  }
}
