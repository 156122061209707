
import { Component, Vue } from 'vue-property-decorator'
import { postLicenseRequest } from '@/services/license-client'
import LicenseRenewDialog from '@/components/license/LicenseRenewDialog.vue'

@Component({
  components: { LicenseRenewDialog }
})
export default class TenantExpired extends Vue {
  showLicenseExpired = true
  showRenewDialog = false

  get email() {
    return this.$store.getters.email
  }

  get renewRequested() {
    return this.$store.getters['license/renewRequested']
  }

  set renewRequested(value: boolean) {
    this.$store.commit('license/setLicenseRenewRequested', value)
  }

  createLicenseRequest() {
    postLicenseRequest()
      .then(() => {
        this.showRenewDialog = false
        this.renewRequested = true
      })
  }

  mounted() {
    if (this.renewRequested === null) {
      this.$store.dispatch('license/fetchRenewRequested')
    }
  }
}
