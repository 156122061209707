
import { Component, Vue } from 'vue-property-decorator'
import { Tenant } from '../model'
import { filterOrg, getFormationKeysDistinct } from '@/components/util/PersonsLodashUtil'

@Component({})
export default class InactiveFormations extends Vue {
  get items() {
    return getFormationKeysDistinct(
      filterOrg(this.$store.getters['personfile/persons'], this.$store.getters['tenant/orgKey'])
    )
  }

  get select() {
    return this.$store.getters['tenant/inactiveFormations']
  }

  set select(value) {
    this.$store.dispatch('tenant/patchInactiveFormations', value)
  }

  get tenant(): Tenant {
    return this.$store.getters['tenant/tenant']
  }
}
