
import { Component, Prop, Vue } from 'vue-property-decorator'
import Chart from 'chart.js'
import { Course, CoursesBarchartBar } from '@/components/model'
import BarChartDecorator from '@/components/chart-decorators/BarChartDecorator'
import PersonGroupOverlay from '@/components/persons/search-overlay/PersonGroupOverlay.vue'
import {
  filterCoursesWithAchievedDays,
  getCourseTypesDistinct,
  groupCoursesByType,
  groupCoursesByYear,
  sumAchievedDays
} from '@/components/util/CoursesLodashUtil'
import { InsightsColorPicker } from '@/components/util/InsightsColorPicker'
import CourseGroupOverlay from '@/components/courses/overlay/CourseGroupOverlay.vue'
import { trace } from '@/services/log-client'

@Component({
    components: { CourseGroupOverlay, PersonGroupOverlay, BarChartDecorator }
  }
)
export default class CourseDaysByTypeBarChart extends Vue {
  @Prop()
  courses!: Course[]

  selectedGroups: CoursesBarchartBar = null

  showOverlay = false

  private showCourse(index: number) {
    this.selectedGroups = this.coursesByYearAndType[index]
    this.showOverlay = true
    trace({
      key: 'courses_overlay',
      route: this.$route.path,
      component: 'CourseDaysByTypeBarChart',
      group: this.selectedGroups.label,
      sumAchievedDays: this.selectedGroups.sumAchievedDays
    })
  }

  get coursesWithAchievedDays(): Course[] {
    return filterCoursesWithAchievedDays(this.courses)
  }

  get courseTypesDistinct(): string[] {
    return getCourseTypesDistinct(this.coursesWithAchievedDays)
  }

  get coursesByYearAndType(): CoursesBarchartBar[] {
    return groupCoursesByYear(this.coursesWithAchievedDays)
      .map(group => ({
        label: group.key,
        courses: groupCoursesByType(group.courses),
        sumAchievedDays: sumAchievedDays(group.courses)
      }))
  }

  get chartData(): Chart.ChartData {
    const bars = this.coursesByYearAndType
    const colorPicker = new InsightsColorPicker()
    return {
      labels: bars.map(p => [p.label, '(' + p.sumAchievedDays + ')']),
      datasets: this.courseTypesDistinct.map(t => ({
        label: t,
        backgroundColor: colorPicker.next(),
        data: bars.map(c => this.createData(c, t))
      }))
    }
  }

  private createData(bar: CoursesBarchartBar, courseType: string): number | undefined {
    const byCourseType = bar.courses.find(grp => courseType.includes(grp.key))
    return byCourseType ? sumAchievedDays(byCourseType.courses) : undefined
  }
}

