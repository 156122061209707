
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import { groupPersonsByFormation, PersonGroup2 } from '@/components/util/PersonsLodashUtil'
import { InsightsColorPicker } from '@/components/util/InsightsColorPicker'
import PersonsDonutChart from '@/components/persons/charts/common/PersonsDonutChart.vue'

@Component({
  components: {
    PersonsDonutChart
  }
})
export default class PersonsByFormationDonutChart extends Vue {
  @Prop()
  persons!: Person[]

  @Prop()
  subtitle: string

  get personGroups(): PersonGroup2[] {
    const colorPicker = new InsightsColorPicker()
    return groupPersonsByFormation(this.persons)
      .map(g => ({
        key: g.key,
        persons: g.persons,
        label: this.getFormationLabel(g.key),
        color: colorPicker.next()
      }))
  }

  private getFormationLabel(formationKey: string): string {
    return this.$store.getters['personfile/orgFormations'].find(f => f.key === formationKey).label
  }
}
