import { getOrgs } from '@/services/org-client'

export const org = {
  namespaced: true,
  state: {
    orgs: null
  },
  actions: {
    async fetchOrgs({ commit }) {
      try {
        const response = await getOrgs()
        console.log('fetched orgs')
        commit('setOrgs', response.data)
        return response.data
      } catch (error) {
        console.log(error)
      }
    }
  },
  mutations: {
    setOrgs(state, data) {
      state.orgs = data
    }
  },
  getters: {
    orgs: state => state.orgs
  }
}
