
import { Component, Vue } from 'vue-property-decorator'
import { Tenant } from '@/components/model'
import { format } from 'date-fns'
import { getOrgName } from '@/data/orgs'
import Spinner from '@/components/Spinner.vue'

@Component({
  components: { Spinner },
  filters: {
    date: function (date: Date) {
      return format(new Date(date), 'dd.MM.yyyy')
    }
  }
})
export default class License extends Vue {
  get tenant(): Tenant {
    return this.$store.getters['tenant/tenant']
  }

  get orgName(): string {
    return getOrgName(this.tenant.orgKey, this.$store.getters['org/orgs'])
  }

  get username(): string {
    return this.$store.getters['auth/username']
  }

  mounted() {
    if (!this.tenant) {
      this.$store.dispatch('tenant/fetchTenant')
    }
  }
}
