import { CSV_COURSE_MAPPING_SCHEMA, CSV_FIELD, CSV_FIELD_MAPPING } from '@/components/model'
import { info } from '@/services/log-client'
import { CSVCOURSE_MAPPING } from '@/components/schema/csv_course_mapping'

function findMapping(field: CSV_FIELD, csvPropertyNames: string[]): CSV_FIELD_MAPPING {
  // OPTIONAL PROPERTIES: Wrong colum names (e.g. NachName) will set the field (name) to undefined
  // REQUIRED PROPERTIES: Will be ensured to be present, but can be null or empty

  const mapping = field.mappings
    .find(mapping => csvPropertyNames.includes(mapping.csvFieldname))

  if (field.required === true && !mapping) {
    throw new Error(`no mapping found for field ${field.name}`)
  }

  return mapping
}

export function createSchema(csvLines: any[]): CSV_COURSE_MAPPING_SCHEMA {
  const csvPropertyNames = Object.getOwnPropertyNames(csvLines[0])

  console.log(csvPropertyNames)

  info({
    key: 'first_csv_row',
    firstCsvRow: `[${csvPropertyNames}]`
  })

  return {
    status: findMapping(CSVCOURSE_MAPPING.status, csvPropertyNames),
    courseNumber: findMapping(CSVCOURSE_MAPPING.courseNumber, csvPropertyNames),
    name: findMapping(CSVCOURSE_MAPPING.name, csvPropertyNames),
    type: findMapping(CSVCOURSE_MAPPING.type, csvPropertyNames),
    bzgArt: findMapping(CSVCOURSE_MAPPING.bzgArt, csvPropertyNames),
    participationType: findMapping(CSVCOURSE_MAPPING.participationType, csvPropertyNames),
    functionAbbr: findMapping(CSVCOURSE_MAPPING.functionAbbr, csvPropertyNames),
    eoCode: findMapping(CSVCOURSE_MAPPING.eoCode, csvPropertyNames),
    location: findMapping(CSVCOURSE_MAPPING.location, csvPropertyNames),
    refNr: findMapping(CSVCOURSE_MAPPING.refNr, csvPropertyNames),
    startDate: findMapping(CSVCOURSE_MAPPING.startDate, csvPropertyNames),
    startTime: findMapping(CSVCOURSE_MAPPING.startTime, csvPropertyNames),
    endDate: findMapping(CSVCOURSE_MAPPING.endDate, csvPropertyNames),
    endTime: findMapping(CSVCOURSE_MAPPING.endTime, csvPropertyNames),
    durationDays: findMapping(CSVCOURSE_MAPPING.durationDays, csvPropertyNames),
    achievedDays: findMapping(CSVCOURSE_MAPPING.achievedDays, csvPropertyNames)
  }
}
