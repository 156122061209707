
import { Component, Vue } from 'vue-property-decorator'

@Component
export default class ScrollToTop extends Vue {
  show = false

  onScroll(e) {
    if (typeof window === 'undefined') return
    const top = window.pageYOffset || e.target.scrollTop || 0
    this.show = top > 20
  }

  toTop() {
    this.$vuetify.goTo(0)
  }
}
