
import { Component, Prop, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import { groupPersonsByUnit, PersonGroup2 } from '@/components/util/PersonsLodashUtil'
import PersonsDonutChart from '@/components/persons/charts/common/PersonsDonutChart.vue'
import { getUnitColor } from '@/data/units'

@Component({
  components: { PersonsDonutChart }
})
export default class PersonsByRegionDonutChart extends Vue {
  @Prop()
  persons!: Person[]

  get personGroups(): PersonGroup2[] {
    return groupPersonsByUnit(this.persons)
      .map(g => ({
        key: g.key,
        persons: g.persons,
        label: g.key,
        color: getUnitColor(g.key)
      }))
  }
}
