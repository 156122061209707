import { getUserprofile, patchAcceptedTerms, patchPreferredEncoding } from '@/services/userprofile-client'

export const userprofile = {
  namespaced: true,
  state: {
    userprofile: null
  },
  actions: {
    async fetchUserprofile({ commit }) {
      try {
        const response = await getUserprofile()
        console.log('fetched userprofile')
        commit('setUserprofile', response.data)
      } catch (error) {
        console.log(error)
      }
    },
    async patchPreferredEncoding({ commit }, encoding) {
      try {
        commit('setPreferredEncoding', encoding)
        await patchPreferredEncoding(encoding)
      } catch (error) {
        console.log(error)
      }
    },
    async acceptTerms({ commit }, value) {
      try {
        commit('setAcceptedTerms', value)
        await patchAcceptedTerms(value)
      } catch (error) {
        console.log(error)
      }
    }
  },
  mutations: {
    setUserprofile(state, data) {
      state.userprofile = data
    },
    setPreferredEncoding(state, data: string) {
      state.userprofile.preferredEncoding = data
    },
    setAcceptedTerms(state, data: boolean) {
      state.userprofile.acceptedTerms = data
    },
    prune(state) {
      state.userprofile = null
    }
  },
  getters: {
    userprofile: state => {
      return state.userprofile
    },
    preferredEncoding: state => {
      return state.userprofile?.preferredEncoding
    },
    acceptedTerms: state => {
      return state.userprofile?.acceptedTerms
    }
  }
}
