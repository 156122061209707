
import { Component, Vue } from 'vue-property-decorator'
import { Person } from '@/components/model'
import {
  filterOrg,
  filterOrgActive,
  filterOrgInactive,
  groupPersonsByFormation,
  PersonGroup
} from '@/components/util/PersonsLodashUtil'
import PersonsByRegionDonutChart from '@/components/persons/charts/PersonsByRegionDonutChart.vue'
import ScrollToTop from '@/components/ScrollToTop.vue'
import PersonsByYearBarChart from '@/components/persons/charts/PersonsByYearBarChart.vue'
import PersonsByUnitDonutChart from '@/components/persons/charts/PersonsByUnitDonutChart.vue'
import PersonsByUnitBarChart from '@/components/persons/charts/PersonsByUnitBarChart.vue'
import PrognosisByUnitBarChart from '@/components/persons/charts/PrognosisByUnitBarChart.vue'
import PersonsByCityBarChart from '@/components/persons/charts/PersonsByCityBarChart.vue'
import EntlassungenByUnitBarChart from '@/components/persons/charts/EntlassungenByUnitBarChart.vue'
import { getOrgName } from '@/data/orgs'
import PersonCounterListItem from '@/components/persons/counters/PersonCounterListItem.vue'

@Component({
  components: {
    PersonCounterListItem,
    EntlassungenByUnitBarChart,
    PersonsByCityBarChart,
    PrognosisByUnitBarChart,
    PersonsByUnitBarChart,
    PersonsByUnitDonutChart,
    PersonsByYearBarChart,
    ScrollToTop,
    PersonsByRegionDonutChart
  }
})
export default class OrgCharts extends Vue {
  get orgName(): string {
    return getOrgName(this.$store.getters['tenant/orgKey'], this.$store.getters['org/orgs'])
  }

  get orgPersons(): Person[] {
    return filterOrg(this.$store.getters['personfile/persons'], this.$store.getters['tenant/orgKey'])
  }

  get orgAktivPersons(): Person[] {
    return filterOrgActive(this.$store.getters['personfile/persons'], this.$store.getters['tenant/tenant'])
  }

  get orgInaktivPersons(): Person[] {
    return filterOrgInactive(this.$store.getters['personfile/persons'], this.$store.getters['tenant/tenant'])
  }

  get orgInaktivPersonsByFormation(): PersonGroup[] {
    return groupPersonsByFormation(this.orgInaktivPersons)
  }

  private getFormationLabel(formationKey: string): string {
    return this.$store.getters['personfile/orgFormations'].find(f => f.key === formationKey).label
  }
}
